.notifications-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.notifications-loading, 
.notifications-error, 
.no-notifications {
  text-align: center;
  padding: 20px;
  color: #666;
}

.notifications-error {
  color: #f44336;
}

.notifications-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.notifications-table th,
.notifications-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.notifications-table thead tr {
  background-color: #f8f8f8;
  border-bottom: 2px solid #ddd;
}

.notifications-table tbody tr:hover {
  background-color: #f5f5f5;
}

.notifications-table tr.unread {
  font-weight: bold;
  background-color: rgba(66, 139, 202, 0.1);
}

.notifications-table tr.read {
  color: #888;
}

/* DataTable specific styles */
.notificationsTable_wrapper .dataTables_filter {
  margin-bottom: 15px;
}

.notificationsTable_wrapper .dataTables_length {
  margin-bottom: 15px;
}

.notificationsTable_wrapper .dataTables_paginate {
  margin-top: 15px;
}

.notificationsTable_wrapper .dataTables_info {
  color: #666;
  margin-top: 15px;
}

/* Styling for the notification rows */
.notificationsTable tbody tr.unread td {
  font-weight: bold;
  background-color: rgba(66, 139, 202, 0.1);
}

.notificationsTable tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

/* Badge styling */
.badge.bg-warning {
  color: #000;
}

/* Dropdown action menu styling */
.dropdown-menu {
  min-width: 10rem;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  color: #d0d0d0;
  background-color: #2b2c40;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem;
}

.dropdown-item {
  padding: 0.532rem 1.25rem;
  color: #d0d0d0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

/* User avatar styling */
.avatar-initial {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
}

.bg-label-info {
  background-color: rgba(3, 195, 236, 0.2) !important;
  color: #03c3ec !important;
}

.avatar-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-circle {
  object-fit: cover;
}

/* React Select custom styles */
.react-select-container {
  color: #d0d0d0;
}

.react-select__value-container {
  padding: 0.5rem 0.75rem;
}

.react-select__dropdown-indicator {
  padding: 0.5rem;
}

.react-select__menu {
  margin-top: 0;
  margin-bottom: 0;
}

.react-select__option {
  padding: 10px 15px;
}

.react-select__option--is-focused {
  background-color: rgba(105, 108, 255, 0.1);
}

.react-select__option--is-selected {
  background-color: #696cff !important;
}

.react-select__indicator-separator {
  background-color: #444564;
}

/* Format user option with avatar and details */
.avatar-option {
  display: flex;
  align-items: center;
}

.avatar-option__image {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.avatar-option__info {
  display: flex;
  flex-direction: column;
}

.avatar-option__name {
  font-weight: 500;
}

.avatar-option__email {
  font-size: 12px;
  color: #888;
}
