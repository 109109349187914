@import 'datatables.net-dt';

table.dataTable>tbody>tr>th,
table.dataTable>tbody>tr>td,
table.dataTable>thead>tr>th {
  padding: 16px !important;
}

table.dataTable thead tr:first-child th:first-child {
  border-top-left-radius: 15px !important;
}

table.dataTable tbody tr:last-child td:first-child {
  border-bottom-left-radius: 15px !important;
}

table.dataTable thead tr:first-child th:last-child {
  border-top-right-radius: 15px !important;
}

table.dataTable tbody tr:last-child td:last-child {
  border-bottom-right-radius: 15px !important;
}

table.dataTable tbody,
table.dataTable tbody tr,
table.dataTable tbody tr td,
table.dataTable {
  border: none !important;
}

.dropdown-toggle.hide-arrow::after {
  display: none;
}