.form-signin {
    max-width: 330px;
    padding: 1rem;
}
.loginPage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
ul.list-style {
    list-style: circle;
    margin-left: 20px;
}