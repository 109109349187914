:root {
  --primary-color: #F6BE01;
  --primary-color-rgb: rgb(246, 190, 1);
  --primary-color-subtle: #f6bd0130;
  --secondary-color: #0F1820;
  --secondary-color-subtle: #0f182060;
  --light-background-color: #FFFFFF;
  --dark-background-color: #0F1820;
  --text-color: #000000;
}

a:hover {
  color: var(--primary-color) !important;
}

body.bg-body-tertiary {
  background-color: var(--dark-background-color) !important;
}

.btn-outline-primary {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-outline-primary:hover {
  background-color: var(--primary-color) !important;
  color: var(--dark-background-color) !important;
}

.text-secondary{
  color: var(--secondary-color) !important;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--dark-background-color) !important;
}

.btn-primary:hover {
  background-color: transparent !important;
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.btn-secondary {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color: var(--light-background-color) !important;
}

.btn-secondary:hover {
  background-color: transparent !important;
  border-color: var(--secondary-color) !important;
  color: #fff !important;
}

.cursor-pointer {
  cursor: pointer;
}

.sidebar {
  min-height: 100vh;
}

.hidden{
  display: none;
}

.underLink {
  text-decoration: none;
  color: var(--primary-color);
  border-bottom: 1px dashed var(--primary-color);
}

.user-profile-tab .nav-item .nav-link.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

[data-bs-theme=dark] .bg-white {
  background-color: #fff !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}

.bg-primary h4 {
  color: var(--dark-background-color) !important;
}

.object-fit-cover {
  object-fit: cover;
  object-position: center;
}

.bg-secondary{
  background-color: var(--secondary-color) !important;
}

.bg-secondary-subtle {
  background-color: var(--secondary-color-subtle) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.bg-primary-subtle {
  background-color: var(--primary-color-subtle) !important;
}

.btn.bg-primary-subtle:hover {
  background-color: var(--primary-color) !important;
  color: var(--secondary-color) !important;
}

.text-bg-primary {
  color: var(--primary-color) !important;
}

#main-wrapper {
  width: 100vw;
}

.dt-container div.dt-layout-row:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

.dt-container div.dt-layout-row:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
}

.dt-container div.dt-layout-row:first-child .dt-layout-cell.dt-layout-start .dt-length select {
  margin-right: 10px;
  width: auto;
  display: inline-block;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  padding-right: .625rem;
  font-size: .765625rem;
  border-radius: 5px;
  background-color: #333f55;
  border: 1px solid #333f55;
}

.dt-container div.dt-layout-row:first-child .dt-layout-cell.dt-layout-end input {
  background-color: #333f55;
  border: 1px solid #333f55;
  padding: .25rem .5rem;
  font-size: .765625rem;
  border-radius: 5px;
  margin-left: .5em;
  display: inline-block;
  width: auto;
}

.dt-container div.dt-layout-row:last-child .dt-paging nav button.dt-paging-button {
  cursor: pointer !important;
  padding: 6px 12px !important;
  border: 1px solid var(--bs-border-color) !important;
  border-radius: var(--bs-border-radius) !important;
  margin: 0 3px !important;
  color: #fff !important;
  background-color: transparent !important;
}

.dt-container div.dt-layout-row:last-child .dt-paging nav button.dt-paging-button.current {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.dt-container div.dt-layout-row:last-child .dt-paging nav button.dt-paging-button:not(.current):hover {
  color: var(--primary-color) !important;
}

div.dt-container .dt-paging .dt-paging-button:hover {
  background: transparent !important;
}

:root.dark table.dataTable thead>tr>th.dt-orderable-asc:hover,
:root.dark table.dataTable thead>tr>th.dt-orderable-desc:hover,
:root.dark table.dataTable thead>tr>td.dt-orderable-asc:hover,
:root.dark table.dataTable thead>tr>td.dt-orderable-desc:hover,
:root[data-bs-theme=dark] table.dataTable thead>tr>th.dt-orderable-asc:hover,
:root[data-bs-theme=dark] table.dataTable thead>tr>th.dt-orderable-desc:hover,
:root[data-bs-theme=dark] table.dataTable thead>tr>td.dt-orderable-asc:hover,
:root[data-bs-theme=dark] table.dataTable thead>tr>td.dt-orderable-desc:hover {
  outline: none !important;
  color: #fff !important;
}

table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
  text-align: left !important;
}

[data-bs-theme=dark] .form-control:focus,
[data-bs-theme=dark] .form-select:focus {
  border-color: var(--primary-color) !important;
}

.form-check-input.primary:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%230F1820' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:focus {
  border-color: var(--primary-color) !important;
  box-shadow: var(--primary-color-subtle) !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.nav-icon-hover-bg:hover {
  background-color: var(--primary-color-subtle) !important;
  color: var(--primary-color) !important;
}

.nav-icon-hover-bg:hover .nav-link {
  color: var(--primary-color) !important;
}

body div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: var(--secondary-color) !important;
}

body div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color: var(--primary-color) !important;
}

div:where(.swal2-icon) {
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--primary-color) !important;
  border-radius: 15px;
  background-color: var(--primary-color-subtle) !important;
  min-height: 250px;
  cursor: pointer;
}

.dropzone:hover {
  opacity: 0.8;
}

.dropzone p {
  color: var(--primary-color) !important;
}

.dropzone div {
  display: flex;
  flex-direction: column;
}

.is-invalid .css-m73g3j-control {
  border-color: #fa896b !important;
  padding-right: calc(1.5em + 16px);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa896b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa896b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + 4px) center;
  background-size: calc(.75em + 8px) calc(.75em + 8px);
}

.tox .tox-tbtn,
[data-bs-theme=dark] .tox .tox-tbtn {
  background: transparent;
}

.tox .tox-edit-area::before {
  border-color: var(--primary-color) !important;
}

.btn-grey {
  background: #e8e8e8;
  border: 1px solid #e8e8e8 !important;
  color: var(--secondary-color);
}

.btn-grey:hover {
  background: transparent;
  color: #e8e8e8 !important;
}

.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%230F1820'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus:checked {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%230F1820'/%3e%3c/svg%3e");
}

.spin {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1500ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1500ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.custom-select .form-select-custom {
  background: transparent !important;
  color: #fff !important;
  border-color: #333f55 !important;
  border: 1px solid;
  border-radius: 5px;
}

.custom-select .css-1p3m7a8-multiValue {
  background: var(--primary-color) !important;
  color: var(--secondary-color) !important;
}

.custom-select .css-v7duua:hover {
  background-color: transparent !important;
  color: #DE350B;
}

table.dataTable thead > tr > th span.dt-column-order:before {
  bottom: 55% !important;
}

.workshopCategories span.badge {
  font-size: 12px;
}

.limitLines{
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 6;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical; 
  margin-bottom: 20px;
}

.announcement-content a{
  text-decoration: underline;
  color: var(--primary-color);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  background: var(--primary-color);
  color: var(--secondary-color);
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}